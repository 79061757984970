import React, { useState } from "react"
import { AnimatedLink } from "@components/shared"

import { button__tertiary } from "@styles/button.module.scss"
import {
  paragraph__gray,
  title__navy,
  description__navy,
} from "@styles/typography.module.scss"
import Section from "./Section"
import {
  background,
  shopping,
  shopping__wrapper,
  shopping__wrapper__examples,
  shopping__wrapper__project,
  active,
} from "./styles/shopping.module.scss"

import { LaptopSVG } from "./icons"

const heading = "Oferta"
const description =
  "Potrzebujesz bloga lub sklepu na wordpressie? Zerknij do zakładki z ofertą!"

const content = {
  title: "Nazwa projektu",
  content:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.",
  img: LaptopSVG,
}

const items = [
  {
    id: 1,
    name: "Przykładowa realizacja B2B",
    content,
  },
  {
    id: 2,
    name: "Przykładowa realizacja B2C",
    content,
  },
  {
    id: 3,
    name: "Przykładowa realizacja B2C + B2B",
    content,
  },
]

const Shopping: React.FC = (): JSX.Element => {
  const [activeId, setActiveId] = useState(items[0].id)

  const handleChangeActive = (id: number) => setActiveId(id)

  return (
    <Section
      backgroundClass={background}
      sectionClass={shopping}
      headingText={heading}
      descriptionText={description}
    >
      <div className={shopping__wrapper}>
        <div className={shopping__wrapper__examples}>
          {items.map(({ id, name }) => (
            <button
              type="button"
              key={id}
              onClick={() => handleChangeActive(id)}
              onKeyDown={() => handleChangeActive(id)}
              tabIndex={0}
            >
              <h3
                className={`${description__navy} ${activeId === id && active}`}
              >
                {name}
              </h3>
            </button>
          ))}
        </div>

        <div className={shopping__wrapper__project}>
          <div>
            <h4 className={title__navy}>{content.title}</h4>
            <p className={paragraph__gray}>{content.content}</p>
            <AnimatedLink to="/" className={button__tertiary} type="button">
              Dowiedz się więcej
            </AnimatedLink>
          </div>
          <img alt="laptop with project" src={content.img} />
        </div>
      </div>
    </Section>
  )
}

export default Shopping
