import React from "react"
import { Link } from "gatsby"

import { paragraph__gray, icon__small } from "@styles/typography.module.scss"
import { button__primary } from "@styles/button.module.scss"
import Section from "./Section"
import {
  background,
  conditions,
  conditions__content,
  conditions__footer,
} from "./styles/conditions.module.scss"

import {
  SignatureSVG,
  ReliabilitySVG,
  GrowthSVG,
  BookSVG,
  SpeechSVG,
} from "./icons"

const heading = "Warunki"
const description = "Czego wymagamy od naszych partnerów?"
const buttonText = "Zobacz jak pracujemy"
const items = [
  {
    id: 1,
    content: "Otwartej głowy na dynamiczne zmiany",
    icon: BookSVG,
  },
  {
    id: 2,
    content:
      "Chęci powiększania skali swojego sklepu głowy na dynamiczne zmiany",
    icon: GrowthSVG,
  },
  {
    id: 3,
    content: "Zaufania w sprawach technologii",
    icon: ReliabilitySVG,
  },
  {
    id: 4,
    content: "Sporadycznego przyznania nam racji",
    icon: SpeechSVG,
  },
]

const Conditions: React.FC = (): JSX.Element => (
  <Section
    backgroundClass={background}
    sectionClass={conditions}
    headingText={heading}
    descriptionText={description}
  >
    <div className={conditions__content}>
      <ul>
        {items.map(({ id, content, icon }) => (
          <li key={id}>
            <img className={icon__small} alt="" src={icon} />
            <p className={paragraph__gray}>{content}</p>
          </li>
        ))}
      </ul>
      <img alt="signature" src={SignatureSVG} />
    </div>

    <div className={conditions__footer}>
      <Link to="/" className={button__primary} type="button">
        {buttonText}
      </Link>
      <img alt="signature" src={SignatureSVG} />
    </div>
  </Section>
)

export default Conditions
