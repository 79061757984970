import React from "react"

import { ItemSlider } from "@components/shared"
import Section from "./Section"
import {
  background,
  reviews,
  reviews__wrapper,
  reviews__wrapper__list,
  reviewSlider,
} from "./styles/reviews.module.scss"

// import XboxSVG from './icons/xbox.svg'

const heading = "Opinie"
const description = "Co nasi partnerzy sądzą o współpracy"

const reviewList = [
  {
    title: "John1 Doe na temat Great Gatsby Store",
    subtitle: "Apple",
    content:
      "“The professionalism and personalized service that we got is unlike any other company we worked with and they made us feel so comfortable. Incredibly trustworthy and high value.“ ",
  },
  {
    title: "John2 Doe na temat Great Gatsby Store",
    subtitle: "Apple",
    content:
      "“The professionalism and personalized service that we got is unlike any other company we worked with and they made us feel so comfortable. Incredibly trustworthy and high value.“ ",
  },
  {
    title: "John3 Doe na temat Great Gatsby Store",
    subtitle: "Apple",
    content:
      "“The professionalism and personalized service that we got is unlike any other company we worked with and they made us feel so comfortable. Incredibly trustworthy and high value.“ ",
  },
]

// const partners = [
//   {
//     name: 'Xbox',
//     logo: XboxSVG,
//   },
//   {
//     name: 'Xbox',
//     logo: XboxSVG,
//   },
//   {
//     name: 'Xbox',
//     logo: XboxSVG,
//   },
//   {
//     name: 'Xbox',
//     logo: XboxSVG,
//   },
//   {
//     name: 'Xbox',
//     logo: XboxSVG,
//   },
// ]

const Reviews: React.FC = (): JSX.Element => (
  <Section
    backgroundClass={background}
    sectionClass={reviews}
    headingText={heading}
    descriptionText={description}
  >
    <div className={reviews__wrapper}>
      <ItemSlider
        items={reviewList}
        observeValue={(i) => i.title}
        name="reviews"
        className={reviewSlider}
      />
      {/* <div className={reviews__wrapper__list}>
          {partners.map(({ name, logo }) => (
            <img key={name} alt={name} src={logo} />
          ))}
        </div> */}
    </div>
  </Section>
)

export default Reviews
