import React from "react"

import { ItemSlider } from "@components/shared"
import { quote__primary__white } from "@styles/typography.module.scss"
import Section from "./Section"

import {
  background,
  attention,
  attention__quote,
  sliderWrapper,
  attentionSlider,
} from "./styles/attention.module.scss"

import {
  DiagramSVG,
  BusinessSVG,
  PieChartSVG,
  TeamSVG,
  InfluenceSVG,
  ArmorSVG,
} from "./icons"
const translation = {
  pl:{
    heading:"Dlaczego My",
    description:"Jako programiści i właściciele biznesów internetowych codziennie odpowiadamy na pytania:",
    quote:"Niczym sztuczną inteligencją w zbroi będziemy myśleć o tym wszystkim za Ciebie sugerując kolejne najbardziej opłacalne kierunki lotu"
  },
  en:{
    heading:"Why Us",
    description:"As programmers and owners of Internet businesses we answer questions every day:",
    quote:"Like an artificial intelligence in armour, we will think about it all for you by suggesting the next most profitable flight directions"
  }
}


const questions = [
  {
    pl:{
      title: "Lepsza Oferta",
      content: "Jak ciągle ulepszać sklep, aby skuteczniej zarabiał na siebie",
    },
    en:{
      title:"Better offer",
      content:"How to continuously improve your store to make it more effective in earning money"
    },
    icon: DiagramSVG,
  },
  {
    pl:{
      title:"Content Marketing",
      content:"Jak pokazać odwiedzającemu odpowiedni post, aby zachęcić do zakupu"
    },
    en:{
      title:"Content Marketing",
      content:"How to show a visitor the right post to encourage them to buy"
    },
    icon: BusinessSVG,
  },
  {
    pl:{
      title:"Analiza Rynku",
      content:"Jak testować różne rozwiązania i szybko dopasowywać się do rynku i trendów",
    },
    en:{
      title:"Market analysis",
      content:"How to test different solutions and quickly adapt to market and trends",
    },
    icon: PieChartSVG,
  },
  {
    pl:{
      title:"Sprawne Zarządzanie",
      content:"Jak automatyzować procesy klientów oraz administracyjne, aby podnieść wydajność i rentowność",
    },
    en:{
      title:"Efficient Management",
      content:"How to automate customer and administrative processes to increase efficiency and profitability",
    },
    icon: TeamSVG,
  },
  {
    pl:{
      title:"Analityka Ruchu",
      content:"Jak analizować dane z wielu źródel w skuteczny sposób i wyciągać stamtąd wiedzę",
    },
    en:{
      title:"Motion Analytics",
      content:"How to analyze data from multiple sources in an effective way and draw knowledge from them",
    },
    icon: InfluenceSVG,
  },
]


const Attention: React.FC = (): JSX.Element => {
  let language = "pl"
  if(typeof window !== "undefined"){
    language = localStorage.getItem("language").replace("/","")
  }
  return (
    <Section
      backgroundClass={background}
      sectionClass={attention}
      headingText={translation[language].heading}
      descriptionText={translation[language].description}
      navyTheme
    >
      <ul>
        {questions.map((el) => (
          <li key={el[language].title}>
            <img alt={el[language].title} src={el.icon} />
            <h3>{el[language].title}</h3>
            <p>{el[language].content}</p>
          </li>
        ))}
      </ul>
  
      <div className={sliderWrapper}>
        <ItemSlider
          items={questions}
          observeValue={(i) => i.title}
          className={attentionSlider}
          name="attention"
        />
      </div>
  
      <div className={attention__quote}>
        <blockquote className={quote__primary__white}>quote</blockquote>
        <img alt="armor" src={ArmorSVG} />
      </div>
    </Section>
  )
}

export default Attention
