import React from "react"
import { Link } from "gatsby"

import { button__red } from "@styles/button.module.scss"
import { description__navy } from "@styles/typography.module.scss"
import { wrapper } from "./styles/specialButton.module.scss"

const SpecialButton: React.FC = (): JSX.Element => (
  <div className={wrapper}>
    <h2 className={description__navy}>Dość tych przykładów</h2>
    <Link className={button__red} to="/">
      Zbuduj mi zbroję!
    </Link>
  </div>
)

export default SpecialButton
