import React, { useState, useCallback } from "react"

import { button__primary } from "@styles/button.module.scss"
import { quote__secondary } from "@styles/typography.module.scss"
import { IContactState } from "../types"
import Section from "./Section"

import { searchForErrors, clearErrors } from "./common"
import {
  contact,
  contact__wrapper,
  error__select,
  error__input,
} from "./styles/contact.module.scss"

import { ConversationSVG } from "./icons"

const heading = "Porozmawiajmy"
const description = "Zostaw swój e-mail, a my się odezwiemy!"
const quoteText =
  "W Gatsby Great Store wdrażamy tylko takie rozwiązania, których jesteśmy pewni, że będą się zwracać i na siebie pracować."

const options = [
  "Option Number 1",
  "Option Number 2",
  "Option Number 3",
  "Option Number 4",
]

const defaultState: IContactState = {
  inputValue: "",
  selectedValue: "question",
}

const Contact: React.FC = (): JSX.Element => {
  const [formState, setFormState] = useState<IContactState>(defaultState)
  const { inputValue, selectedValue } = formState

  const [errorMessage, setErrorMessage] = useState<string>("")
  const [errorElements, setErrorElements] = useState<string[]>([])

  const handleInvalidInput = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      e.preventDefault()
      searchForErrors(formState, setErrorMessage, setErrorElements)
    },
    [formState]
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearErrors(setErrorMessage, setErrorElements, e.target.name)

      const updatedState = {
        ...formState,
        inputValue: e.target.value,
      }
      setFormState(updatedState)
    },
    [formState]
  )

  const handleSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      clearErrors(setErrorMessage, setErrorElements, e.target.name)

      const updatedState = {
        ...formState,
        selectedValue: e.target.value,
      }
      setFormState(updatedState)
    },
    [formState]
  )

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      searchForErrors(formState, setErrorMessage, setErrorElements)

      if (!errorMessage && !errorElements.length) {
        console.log("success")
      }
    },
    [formState]
  )

  return (
    <Section
      sectionClass={contact}
      headingText={heading}
      descriptionText={description}
    >
      <div className={contact__wrapper}>
        <blockquote className={quote__secondary}>{quoteText}</blockquote>

        <form onSubmit={handleSubmit}>
          <select
            className={errorElements.includes("select") ? error__select : ""}
            value={selectedValue}
            name="select"
            required
            onChange={handleSelect}
          >
            <option value="question" disabled hidden>
              Czego dotyczy wiadomość?
            </option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <label
            className={errorElements.includes("email") ? error__input : ""}
            htmlFor="email"
          >
            <input
              required
              type="text"
              name="email"
              id="email"
              value={inputValue}
              onInvalid={handleInvalidInput}
              onChange={handleChange}
              autoComplete="off"
            />
            <span>E-mail</span>
          </label>
          <button className={button__primary} type="submit">
            Wyślij!
          </button>
        </form>

        {errorMessage && <p>{errorMessage}</p>}

        <img alt="conversation" src={ConversationSVG} />
      </div>
    </Section>
  )
}

export default Contact
