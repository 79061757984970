import React from "react"
import {
  heading,
  description__white,
  description__navy,
} from "@styles/typography.module.scss"
import { wrapper } from "./styles/section.module.scss"

interface ISection {
  sectionClass: string
  backgroundClass?: string
  navyTheme?: boolean
  headingText?: string
  descriptionText?: string
}
// do components refactor
const Section: React.FC<ISection> = ({
  children,
  backgroundClass = "",
  sectionClass,
  navyTheme = false,
  headingText,
  descriptionText,
}): JSX.Element => (
  <div className={`${wrapper} ${backgroundClass}`}>
    <section>
      <h2 className={heading}>{headingText}</h2>
      <p className={navyTheme ? description__white : description__navy}>
        {descriptionText}
      </p>

      <div className={sectionClass}>{children}</div>
    </section>
  </div>
)

export default Section
