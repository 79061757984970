import { Dispatch, SetStateAction } from "react"
import { IContactState } from "../../types"

const emailPattern = new RegExp(/^\S+@\S+\.\S+$/)

type setter<T> = Dispatch<SetStateAction<T>>

const searchForErrors = (
  state: IContactState,
  setError: setter<string>,
  setErrorElements: setter<string[]>
): void => {
  const { inputValue, selectedValue } = state

  if (!inputValue.match(emailPattern)) {
    setError("Wpisz poprawny adres e-mail")
    setErrorElements((prevState) => [...prevState, "email"])
  }

  if (!selectedValue || selectedValue === "question") {
    setError("Wybierz czego dotyczy wiadomość")
    setErrorElements((prevState) => [...prevState, "select"])
  }
}

const clearErrors = (
  setError: setter<string>,
  setErrorElements: setter<string[]>,
  name: string
): void => {
  setError("")
  setErrorElements((prevState) => prevState.filter((elm) => elm !== name))
}

export { searchForErrors, clearErrors }
