import React from "react"

import {
  quote__primary,
  description__navy,
} from "@styles/typography.module.scss"
import DoubleSquare from "@styles/icons/double-square.svg"

import Section from "./Section"
import {
  background,
  square__wrapper,
  square__left,
  square__right,
  mission,
  mission__wrapper,
  mission__wrapper__content,
  mission__wrapper__image,
} from "./styles/mission.module.scss"

import { UpperHalfSVG } from "./icons"

const description = "Nasza główna misja"
const quote =
  "Niczym zbroja Ironamana chcemy wzmacniać możliwości właściciela firmy z sektora MŚP, aby szybko mógł dolecieć do wyznaczonego przez siebie celu, jednocześnie zdejmując ciężar myślenia o technikaliach"

const Mission: React.FC = (): JSX.Element => (
  <Section backgroundClass={background} sectionClass={mission}>
    <div className={square__wrapper}>
      <img className={square__left} src={DoubleSquare} alt="" />
      <img className={square__right} src={DoubleSquare} alt="" />
    </div>

    <div className={mission__wrapper}>
      <div className={mission__wrapper__content}>
        <p className={description__navy}>{description}</p>
        <blockquote className={quote__primary}>{quote}</blockquote>
      </div>

      <div className={mission__wrapper__image}>
        <img alt="iron-man" src={UpperHalfSVG} />
      </div>
    </div>
  </Section>
)

export default Mission
