import React from "react"

import { paragraph__gray, title__navy } from "@styles/typography.module.scss"
import Section from "./Section"
import { background, values } from "./styles/values.module.scss"

import {
  ReliabilitySVG,
  RewindSVG,
  MachineLearningSVG,
  GroupSVG,
} from "./icons"

const heading = "Kim jesteśmy"
const description = "Software House oferujący nowoczesne podejście do ebiznesów"

const valueList = [
  {
    name: "Doświadczenie",
    icon: ReliabilitySVG,
    content:
      "Sami prowadzimy kilka swoich projektów i regularnie pracujemy nad ich rentownością. Nasz software house ma duże doświadczenie w pracy z modelem dropshippingowym",
  },
  {
    name: "Szybkość",
    icon: RewindSVG,
    content:
      "Doskonale zdajemy sobie sprawe, że w internecie każda sekunda jest ważna, dlatego do naszych sklepów i blogów wykorzystujemy wyłącznie nowoczesne technologie, które sprawiają, że nasze produkty są superszybkie",
  },
  {
    name: "Automatyzacja",
    icon: MachineLearningSVG,
    content:
      "Dowiemy się jak automatyzując procesy odzyskać czas Twój i Twoich pracowaników",
  },
  {
    name: "Wsparcie",
    icon: GroupSVG,
    content:
      "Dla każdego klienta zapewniamy mentoring, wypracowaną latami wiedzę oraz gotowe sprawdzone procesy firmowe",
  },
]

const Values: React.FC = (): JSX.Element => (
  <Section
    backgroundClass={background}
    sectionClass={values}
    headingText={heading}
    descriptionText={description}
  >
    <ul>
      {valueList.map(({ name, icon, content }) => (
        <li key={name}>
          <div>
            <img alt={name} src={icon} />
            <h3 className={title__navy}>{name}</h3>
          </div>
          <p className={paragraph__gray}>{content}</p>
        </li>
      ))}
    </ul>
  </Section>
)

export default Values
